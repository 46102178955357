<template>
  <div component-name="card-blog" class="@container/card-blog flex max-w-full">
    <div
      class="flex flex-col justify-start items-start gap-y-lg gap-x-2xl w-full max-w-full @[900px]/card-blog:flex-row @[900px]/card-blog:items-stretch"
    >
      <txt-heading
        size="small"
        :text="props.layout === 'highlights' ? categoryLabel : subcategory"
        class="w-full @[900px]/card-blog:hidden"
      />

      <!-- <wrapper-reveal
        reveal="fade-from-bottom"
        class="overflow-hidden w-full @[900px]/card-blog:w-7/12"
        :class="{
          'aspect-4/5': asset.aspectRatio === '4 / 5',
          'aspect-1/1': asset.aspectRatio === '1 / 1',
          'aspect-3/1': asset.aspectRatio === '3 / 1',
          'aspect-3/2': asset.aspectRatio === '3 / 2',
          'aspect-16/9': asset.aspectRatio === '16 / 9',
        }"
      > -->
      <wrapper-reveal
        reveal="fade-from-bottom"
        class="overflow-hidden w-full @[900px]/card-blog:w-7/12"
      >
        <btn
          :title="title"
          :action="action"
          class="overflow-hidden block h-full w-full leading-none"
        >
          <media
            ref="tpl_media"
            tag="div"
            v-bind="{ ...asset }"
            :sizes="sizes"
            class="min-w-full min-h-full transition-transform duration-1000 group-hover/btn:scale-105"
            :class="{ '!scale-105': forceMediaHover }"
          />
        </btn>
      </wrapper-reveal>

      <footer
        class="flex flex-col gap-md min-w-full @[900px]/card-blog:w-5/12 @[900px]/card-blog:min-w-0 text-skin-base"
      >
        <div class="hidden @[900px]/card-blog:block w-full">
          <txt-heading
            size="small"
            :text="props.layout === 'highlights' ? categoryLabel : subcategory"
            class="w-full"
          />
        </div>

        <btn
          :title="title"
          :action="action"
          class="block text-heading-6/snug @[900px]/card-blog:text-heading-3 w-10/12 text-left"
          @mouseenter="forceMediaHover = $device.isDesktop"
          @mouseleave="forceMediaHover = false"
        >
          <wrapper-split-text reveal="lines">
            <div v-html="title"></div>
          </wrapper-split-text>
        </btn>

        <wrapper-reveal
          v-if="
            footer.unix &&
            (props.category === 'news' || props.category === 'trends')
          "
          reveal="fade-from-right"
        >
          <txt-geodate
            :unix="footer.unix"
            class="text-overline-small text-skin-muted"
          />
        </wrapper-reveal>

        <wrapper-reveal v-else-if="footerLabel" reveal="fade-from-right">
          <div
            v-html="footerLabel"
            class="text-overline-small text-skin-muted [&>*:first-letter]:uppercase"
          ></div>
        </wrapper-reveal>
      </footer>
    </div>
  </div>
</template>

<script setup>
import { useGeolistStore } from "@/stores/geolist";
const props = defineProps({
  layout: {
    type: String,
    required: false,
    validator: (value) => {
      return ["related", "highlights"].indexOf(value) !== -1;
    },
  },
  category: {
    type: String,
    required: true,
    validator: (value) => {
      return (
        ["projects", "design-stories", "trends", "news"].indexOf(value) !== -1
      );
    },
  },
  subcategory: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  footer: {
    type: Object,
    required: false,
  },
  action: {
    type: Object,
    required: false,
  },
  asset: {
    type: Object,
    required: true,
  },
});
const nuxtApp = useNuxtApp();

const geolistStore = useGeolistStore();
const tpl_media = templateRef("tpl_media");
const { width } = useElementSize(tpl_media);
const forceMediaHover = ref(false);
const sizes = computed(() => {
  let obj = {};

  if (width.value >= 1500) obj = { base: 1800 };
  else if (width.value >= 1300) obj = { base: 1500 };
  else if (width.value >= 1100) obj = { base: 1300 };
  else if (width.value >= 900) obj = { base: 1100 };
  else if (width.value >= 700) obj = { base: 900 };
  else if (width.value >= 600) obj = { base: 700 };
  else if (width.value >= 500) obj = { base: 600 };
  else if (width.value >= 400) obj = { base: 500 };
  else obj = { base: 400 };

  return obj;
});

const categoryLabel = computed(() => {
  let s;

  switch (props.category) {
    case "projects":
      s = nuxtApp.$t.labels.projects;
      break;

    case "design-stories":
      s = nuxtApp.$t.labels.design_stories;
      break;

    case "trends":
      s = nuxtApp.$t.labels.trends;
      break;

    case "news":
      s = nuxtApp.$t.labels.news;
      break;
  }

  return s;
});

const footerLabel = computed(() => {
  let s;
  switch (props.category) {
    case "projects":
      s = `${geolistStore.getCountryLabelByISO(props.footer.country)} - ${
        props.footer.year
      }`;
      break;

    case "design-stories":
      s = `${props.footer.firstname} ${props.footer.lastname}`;
      break;
  }

  return s;
});
</script>
